import Core from 'markdown-it/lib/parser_core';
import StateCore from 'markdown-it/lib/rules_core/state_core';
import Token from 'markdown-it/lib/token';
import { Condition } from '@/model/condition';
import { Markup } from '@/plugin/template/markup';
import { Parser } from '@/plugin/template/parser';

export const inlineConditionsRule: Core.RuleCore = (state: StateCore): boolean => {
  if (!state.env.mdCompiler?.isEnabled) {
    return false;
  }

  for (let index: number = 0; index < state.tokens.length; index += 1) {
    const token: Token = state.tokens[index];

    if (token.type === 'inline' && Markup.isInlineIf(token.content)) {
      const conditions: Condition[] = Markup.extractInlineConditions(token.content);
      const content: string[] = [...token.content];
      let resolvedContent: string = '';

      for (let i: number = conditions.length - 1; i >= 0; i -= 1) {
        const condition: Condition = conditions[i];

        const textAfterCondition: string[] = content.splice(condition.end, content.length);
        if (textAfterCondition.length > 0) {
          resolvedContent = textAfterCondition.join('') + resolvedContent;
        }

        const isValid: boolean = Parser.evaluatePredicate(condition.predicate, state.env.mdCompiler?.formBuilder.model);
        if (isValid) {
          resolvedContent = condition.content + resolvedContent;
        }

        content.splice(content.length - condition.match.length, content.length);
      }

      if (content.length > 0) {
        resolvedContent = content.join('') + resolvedContent;
      }

      token.content = resolvedContent;
    }
  }

  return false;
};
