import Core from 'markdown-it/lib/parser_core';
import StateCore from 'markdown-it/lib/rules_core/state_core';
import Token from 'markdown-it/lib/token';
import { Placeholder } from '@/model/placeholder';
import { Markup } from '@/plugin/template/markup';

export const placeholdersRule: Core.RuleCore = (state: StateCore): boolean => {
  if (!state.env.mdCompiler?.isEnabled) {
    return false;
  }

  if (!state.env.mdCompiler?.config?.placeholders || state.env.mdCompiler?.config?.placeholders?.length === 0) {
    return false;
  }

  const placeholders: Placeholder[] = state.env.mdCompiler?.formBuilder.getPlaceholders();

  for (let index: number = 0; index < state.tokens.length; index += 1) {
    const token: Token = state.tokens[index];

    if (token.type === 'fence' && Markup.hasPlaceholders(token.content, placeholders)) {
      token.content = Markup.replacePlaceholders(token.content, placeholders);
    } else if (token.type === 'inline' && Markup.hasPlaceholders(token.content, placeholders)) {
      token.content = Markup.replacePlaceholders(token.content, placeholders);

      if (token.children && token.children.length > 0) {
        token.children.filter((t: Token): boolean => t.type === 'text')
          .forEach((t: Token): void => {
            t.content = Markup.replacePlaceholders(t.content, placeholders);
          });
      }
    }
  }

  return false;
};
