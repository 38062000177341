import { IPluginConfigFormPreset } from '@/interface/i-plugin-config-form-preset';
import { IPluginConfigFormPresets } from '@/interface/i-plugin-config-form-presets';
import { ApplyPresetEvent } from '@/model/apply-preset-event';
import { FormControlConfig } from '@/model/form-control-config';
import { SelectItem } from '@/model/select-item';
import { FormItemType } from '@/enums/form-item-type';
import { IForm } from '@/interface/i-form';
import { IFormBuilder } from '@/interface/i-form-builder';
import { IFormBuilderConstructor } from '@/interface/i-form-builder-constructor';
import { IFormControl } from '@/interface/i-form-control';
import { FormConfig } from '@/model/form-config';
import { FormControlComponent } from '@/model/form-control-component';
import { Placeholder } from '@/model/placeholder';
import { UpdateModelEvent } from '@/model/update-model-event';
import { Form } from '@/plugin/form/form';
import { FormControlFactory } from '@/plugin/form/form-control-factory';
import { Parser } from '@/plugin/template/parser';

export class FormBuilder implements IFormBuilder {
  public model: Record<string, any>;

  public formsMap: Map<string, IForm>;

  public controlsMap: Map<string, IFormControl>;

  public selectItemsMap: Map<string, SelectItem[]>;

  public components: Record<string, FormControlComponent[]>;

  public presets: Record<string, IPluginConfigFormPreset[]>;

  public placeholders: Placeholder[];

  constructor(options: IFormBuilderConstructor) {
    this.model = {};
    this.formsMap = new Map();
    this.controlsMap = new Map();
    this.selectItemsMap = new Map();
    this.components = {};
    this.presets = {};
    this.placeholders = [];

    this.parseOptions(options);
    this.buildComponents();
  }

  public updateModel(event: UpdateModelEvent): void {
    this.updateControl(event.controlId, event.value);

    this.buildComponents();
  }

  public getComponents(formId: string): FormControlComponent[] {
    return this.components[formId];
  }

  public applyPreset(event: ApplyPresetEvent): void {
    const preset: IPluginConfigFormPreset = this.presets[event.formId][event.index];
    const form: IForm | undefined = this.formsMap.get(event.formId);

    form?.controls.forEach((control: IFormControl): void => {
      this.updateControl(control.id, preset.data[control.id] || control.initialValue);
    });

    this.buildComponents();
  }

  public getPresets(formId: string): string[] {
    return (this.presets[formId] || []).map((preset: IPluginConfigFormPreset): string => preset.label);
  }

  public updateControl(controlId: string, value: any): void {
    const control: IFormControl | undefined = this.getControl(controlId);

    if (typeof control !== 'undefined') {
      control.value = value;
    }
  }

  public getControl(controlId: string): IFormControl | undefined {
    return this.controlsMap.get(controlId);
  }

  public getPlaceholders(): Placeholder[] {
    return this.placeholders.map(
      (item: Placeholder): Placeholder => {
        const control: IFormControl | undefined = this.getControl(item.predicate);
        const initialValue: string = control?.initialValue || '';

        let value: any = Parser.evaluatePredicate(item.predicate, this.model, initialValue);

        if (control?.isInput && value === '') {
          value = initialValue;
        }

        if (control?.isSelect && typeof value !== 'string' && value.value) {
          value = value.value;
        }

        if (control?.isCheckbox) {
          value = value.toString();
        }

        if (typeof value !== 'string') {
          value = '';
        }

        return ({
          ...item,
          value,
        });
      },
    );
  }

  protected parseOptions(options: IFormBuilderConstructor): void {
    Object.entries(options.items || {}).forEach(
      ([id, itemsConfig]: [string, [string, string][]]): void => {
        const items: SelectItem[] = itemsConfig.map(([value, label]: [string, string]): SelectItem => ({
          value,
          label,
        }));

        this.selectItemsMap.set(id, items);
      },
    );

    Object.entries(options.forms || {}).forEach(
      ([id, controls]: [string, FormConfig[]]): void => {
        const form: IForm = new Form({
          id,
          formBuilder: this,
        });

        if (Array.isArray(controls)) {
          const presetConfig: IPluginConfigFormPresets | undefined = controls.find(
            (controlConfig: FormConfig): boolean => controlConfig.type === FormItemType.PRESETS,
          ) as IPluginConfigFormPresets | undefined;

          if (presetConfig) {
            this.presets[form.id] = presetConfig.presets;
          }

          const controlsConfigs: FormControlConfig[] = controls.filter(
            (controlConfig: FormConfig): boolean => controlConfig.type !== FormItemType.PRESETS,
          ) as FormControlConfig[];

          this.parseControls(controlsConfigs, form);
        }

        this.formsMap.set(form.id, form);
      },
    );

    (options.placeholders || []).forEach(
      ([name, predicate]: [string, string]): void => {
        const placeholder: Placeholder = {
          name,
          predicate,
          value: '',
        };

        this.placeholders.push(placeholder);
      },
    );
  }

  protected parseControls(controls: FormControlConfig[], form: IForm, parent?: IFormControl): void {
    const controlFactory: FormControlFactory = new FormControlFactory({
      form,
      selectItemsMap: this.selectItemsMap,
      model: this.model,
      parent,
    });

    controls.forEach((controlConfig: FormControlConfig): void => {
      const control: IFormControl = controlFactory.create(controlConfig);
      this.controlsMap.set(control.id, control);

      if (controlConfig.type === FormItemType.GROUP) {
        if (Array.isArray(controlConfig.children)) {
          this.parseControls(controlConfig.children, form, control);
        }
      }
    });
  }

  protected buildComponents(): void {
    this.formsMap.forEach((form: IForm): void => {
      this.components[form.id] = form.getComponents();
    });
  }
}
