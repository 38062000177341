import MarkdownIt, { PluginWithOptions } from 'markdown-it';
import { CompilerRule } from '@/enums/compiler-rule';
import { blockConditionsRule } from '@/plugin/rules/block-conditions-rule';
import { detectConfigRule } from '@/plugin/rules/detect-config-rule';
import { fenceConditionsRule } from '@/plugin/rules/fence-conditions-rule';
import { inlineConditionsRule } from '@/plugin/rules/inline-conditions-rule';
import { placeholdersRule } from '@/plugin/rules/placeholders-rule';
import { removeInvalidBlockConditionsRule } from '@/plugin/rules/remove-invalid-block-conditions-rule';

export const MarkdownItCompiler: PluginWithOptions = (md: MarkdownIt): void => {
  md.core.ruler.before('normalize', CompilerRule.CORE_FORM_BUILDER, detectConfigRule);
  md.block.ruler.before('table', CompilerRule.BLOCK_CONDITIONS, blockConditionsRule);
  md.core.ruler.before('inline', CompilerRule.CORE_INLINE_CONDITIONS, inlineConditionsRule);
  md.core.ruler.push(CompilerRule.CORE_CONDITIONS, removeInvalidBlockConditionsRule);
  md.core.ruler.push(CompilerRule.CORE_FENCE_CONDITIONS, fenceConditionsRule);
  md.core.ruler.push(CompilerRule.CORE_PLACEHOLDERS, placeholdersRule);
};
