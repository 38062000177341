import Core from 'markdown-it/lib/parser_core';
import StateCore from 'markdown-it/lib/rules_core/state_core';
import Token from 'markdown-it/lib/token';
import { CompilerToken } from '@/enums/compiler-token';
import { Parser } from '@/plugin/template/parser';

export const removeInvalidBlockConditionsRule: Core.RuleCore = (state: StateCore): boolean => {
  if (!state.env.mdCompiler?.isEnabled) {
    return false;
  }

  let shouldRemove: boolean = false;
  let isValid: boolean = false;

  for (let index: number = 0; index < state.tokens.length; index += 1) {
    const token: Token = state.tokens[index];

    switch (token.type) {
      case CompilerToken.IF:
        isValid = Parser.evaluatePredicate(token.info, state.env.mdCompiler?.formBuilder.model);
        shouldRemove = !isValid;
        break;

      case CompilerToken.ELSEIF:
        if (!isValid) {
          isValid = Parser.evaluatePredicate(token.info, state.env.mdCompiler?.formBuilder.model);
          shouldRemove = !isValid;
        } else {
          shouldRemove = true;
        }
        break;

      case CompilerToken.ELSE:
        shouldRemove = isValid;
        break;

      case CompilerToken.ENDIF:
        shouldRemove = false;
        break;

      default:
        if (shouldRemove) {
          state.tokens.splice(index, 1);
          index -= 1;
        }
    }
  }

  return false;
};
