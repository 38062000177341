import { FormControlComponent } from '@/model/form-control-component';
import { IForm } from '@/interface/i-form';
import { IFormBuilder } from '@/interface/i-form-builder';
import { IFormConstructor } from '@/interface/i-form-constructor';
import { IFormControl } from '@/interface/i-form-control';

export class Form implements IForm {
  public id: string;

  public formBuilder: IFormBuilder;

  public controls: IFormControl[];

  constructor(options: IFormConstructor) {
    const {
      id,
      formBuilder,
    }: IFormConstructor = options;

    this.id = id;
    this.formBuilder = formBuilder;
    this.controls = [];
  }

  public addControl(control: IFormControl): Form {
    this.controls.push(control);

    return this;
  }

  public getComponents(): FormControlComponent[] {
    return this.controls
      .filter((control: IFormControl): boolean => control.isRoot)
      .map((control: IFormControl): FormControlComponent => control.getComponent());
  }
}
