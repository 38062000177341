import { IPluginConfig } from '@/interface/i-plugin-config';

export class Parser {
  public static evaluatePredicate<T extends object>(predicate: string, context: T, defaultValue: any = false): any {
    try {
      // eslint-disable-next-line no-new-func,@typescript-eslint/no-implied-eval
      return new Function('v', `with (v) { return ( ${predicate} )}`)(context);
    } catch (e: any) {
      return defaultValue;
    }
  }

  public static evaluateConfig(config: string, defaultValue: any = {}): IPluginConfig {
    const c: string = 'const c = (condition, trueValue, falseValue) => ({condition, trueValue, falseValue})';
    let result;

    try {
      // eslint-disable-next-line no-new-func,@typescript-eslint/no-implied-eval
      result = new Function(`${c}; let config; ${config}; return config;`)();
    } catch (e: any) {
      result = defaultValue;
    }

    return result;
  }
}
