import Core from 'markdown-it/lib/parser_core';
import StateCore from 'markdown-it/lib/rules_core/state_core';
import Token from 'markdown-it/lib/token';
import { Condition } from '@/model/condition';
import { FenceBlockCondition } from '@/model/fence-block-condition';
import { Markup } from '@/plugin/template/markup';
import { Parser } from '@/plugin/template/parser';

const resolveInlineConditions = (content: string, state: StateCore): string => {
  let condition: Condition | null;
  let resolvedContent: string = content;

  // eslint-disable-next-line no-cond-assign
  while ((condition = Markup.extractFirstInlineCondition(resolvedContent)) !== null) {
    const chars: string[] = [...resolvedContent];

    if (Parser.evaluatePredicate(condition.predicate, state.env.mdCompiler?.formBuilder.model)) {
      chars.splice(condition.start, condition.match.length, ...condition.content);
    } else {
      chars.splice(condition.start, condition.match.length);
    }

    resolvedContent = chars.join('');
  }

  return resolvedContent;
};

const getSequence = (from: number, to: number): number[] => {
  const length: number = to - from + 1;
  return Array.from({ length }, (el, index: number): number => index + from);
};

const resolveBlockConditions = (content: string, state: StateCore): string => {
  const lines: string[] = content.split(/\r\n|\r|\n/);
  let start: number;
  let predicate: string;
  const conditions: FenceBlockCondition[][] = [];
  let conditionChain: FenceBlockCondition[] = [];

  lines.forEach((line: string, currentLine: number): void => {
    if (Markup.isIf(line)) {
      start = currentLine;
      predicate = Markup.extractIfCondition(line);
      conditionChain = [];
    } else if (Markup.isElseIf(line)) {
      conditionChain.push({
        start,
        end: currentLine,
        predicate,
        isValid: false,
      });
      start = currentLine;
      predicate = Markup.extractElseIfCondition(line);
    } else if (Markup.isElse(line)) {
      conditionChain.push({
        start,
        end: currentLine,
        predicate,
        isValid: false,
      });
      start = currentLine;
      predicate = 'true';
    } else if (Markup.isEndIf(line)) {
      conditionChain.push({
        start,
        end: currentLine,
        predicate,
        isValid: false,
      });
      conditions.push(conditionChain);
    }
  });

  conditions.forEach((parts: FenceBlockCondition[]): void => {
    // TODO
    // eslint-disable-next-line no-restricted-syntax
    for (const part of parts) {
      if (Parser.evaluatePredicate(part.predicate, state.env.mdCompiler?.formBuilder.model)) {
        part.isValid = true;
        break;
      }
    }
  });

  const linesToRemove: number[] = conditions.reduce(
    (cur: number[], acc: FenceBlockCondition[]) => {
      const linesToRemoveSet: Set<number> = new Set();

      acc.forEach((part: FenceBlockCondition): void => {
        if (part.isValid) {
          linesToRemoveSet.add(part.start);
          linesToRemoveSet.add(part.end);
        } else {
          // TODO
          // eslint-disable-next-line @typescript-eslint/unbound-method
          getSequence(part.start, part.end).forEach(linesToRemoveSet.add, linesToRemoveSet);
        }
      });

      cur.push(...Array.from(linesToRemoveSet));

      return cur;
    },
    [],
  );

  return lines.filter((value: string, index: number) => !linesToRemove.includes(index)).join('\n');
};

const fixLastComma = (content: string): string => content.replace(/,(\s*})/g, '$1');

// Two or more empty lines.
const fixExtraWhitespace = (content: string): string => content.replace(/(\s*\n){3,}/g, '\n\n');

// One or more lines between the last two parenthesis.
const fixLastParenthesisWhitespace = (content: string): string => content.replace(/\s+\n}/g, '\n}');

// One or more lines after the opening parenthesis.
const fixExtraSpaceAfterOpeningParenthesis = (content: string): string => content.replace(/{(\s*\n){2,}/g, '{\n');

// One or more lines before the closing parenthesis.
const fixExtraSpaceBeforeClosingParenthesis = (content: string): string => content.replace(
  /(\s*\n){2,}(\s+)}/g,
  '\n$2}',
);

export const fenceConditionsRule: Core.RuleCore = (state: StateCore): boolean => {
  if (!state.env.mdCompiler?.isEnabled) {
    return false;
  }

  for (let index: number = 0; index < state.tokens.length; index += 1) {
    const token: Token = state.tokens[index];

    if (token.type === 'fence') {
      token.content = resolveInlineConditions(token.content, state);
      token.content = resolveBlockConditions(token.content, state);
      token.content = fixLastComma(token.content);
      token.content = fixExtraWhitespace(token.content);
      token.content = fixLastParenthesisWhitespace(token.content);
      token.content = fixExtraSpaceAfterOpeningParenthesis(token.content);
      token.content = fixExtraSpaceBeforeClosingParenthesis(token.content);
    }
  }

  return false;
};
