import Core from 'markdown-it/lib/parser_core';
import StateCore from 'markdown-it/lib/rules_core/state_core';
import { IPluginConfig } from '@/interface/i-plugin-config';
import { FormBuilder } from '@/plugin/form/form-builder';
import { Parser } from '@/plugin/template/parser';

export const detectConfigRule: Core.RuleCore = (state: StateCore): boolean => {
  if (typeof state.env.mdCompiler !== 'object') {
    state.env.mdCompiler = {};
  }

  let rawConfig: string = '';

  let isEnabled: boolean = state.env.mdCompiler?.isEnabled || false;

  const regex: RegExp = /```javascript form-builder-config([^`]*)```/gm;
  const matches: RegExpMatchArray[] = Array.from(state.src.matchAll(regex));

  if (matches.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    rawConfig = matches[0][1];
    state.src = state.src.replaceAll(regex, '');

    if (!isEnabled && rawConfig !== '') {
      isEnabled = true;

      const config: IPluginConfig = Parser.evaluateConfig(rawConfig);

      state.env.mdCompiler.config = config;
      state.env.mdCompiler.formBuilder = new FormBuilder(config);
    }
  } else {
    isEnabled = false;
    state.env.mdCompiler = {};
  }

  state.env.mdCompiler.isEnabled = isEnabled;

  return false;
};
