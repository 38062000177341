import { Condition } from '@/model/condition';
import { Placeholder } from '@/model/placeholder';

export class Markup {
  protected static IF: string = '^\\s*{{\\s*#if\\s*([^{}]+)\\s*}}\\s*$';

  protected static ELSEIF: string = '^\\s*{{\\s*else\\s+if\\s*([^{}]+)\\s*}}\\s*$';

  protected static ELSE: string = '^\\s*{{\\s*else\\s*[^{}]*}}\\s*$';

  protected static ENDIF: string = '^\\s*{{\\s*\\/if\\s*[^{}]*}}\\s*$';

  protected static INLINE_IF: string = '{{\\s*#if\\s*([^{}]+?)\\s*}}(((?!{{)[^])+){{\\s*\\/if[^{}]*}}';

  public static isIf(text: string): boolean {
    return new RegExp(Markup.IF).test(text);
  }

  public static isElseIf(text: string): boolean {
    return new RegExp(Markup.ELSEIF).test(text);
  }

  public static isElse(text: string): boolean {
    return new RegExp(Markup.ELSE).test(text);
  }

  public static isEndIf(text: string): boolean {
    return new RegExp(Markup.ENDIF).test(text);
  }

  public static isInlineIf(text: string): boolean {
    return new RegExp(Markup.INLINE_IF, 'g').test(text);
  }

  public static extractIfCondition(text: string): string {
    const groups: RegExpExecArray | null = new RegExp(Markup.IF).exec(text);
    return groups && groups.length > 1 ? groups[1].trim() : '';
  }

  public static extractElseIfCondition(text: string): string {
    const groups: RegExpExecArray | null = new RegExp(Markup.ELSEIF).exec(text);
    return groups && groups.length > 1 ? groups[1].trim() : '';
  }

  public static extractInlineConditions(text: string): Condition[] {
    let groups;
    const result: Condition[] = [];
    const reg: RegExp = new RegExp(Markup.INLINE_IF, 'g');

    // eslint-disable-next-line no-cond-assign
    while ((groups = reg.exec(text)) !== null) {
      result.push({
        match: groups[0],
        predicate: groups[1].trim(),
        content: groups[2],
        start: reg.lastIndex - groups[0].length,
        end: reg.lastIndex,
      });
    }

    return result;
  }

  public static extractFirstInlineCondition(text: string): Condition | null {
    const reg: RegExp = new RegExp(Markup.INLINE_IF, 'g');
    const groups: RegExpExecArray | null = reg.exec(text);

    if (groups === null) {
      return null;
    }

    return {
      match: groups[0],
      predicate: groups[1].trim(),
      content: groups[2],
      start: reg.lastIndex - groups[0].length,
      end: reg.lastIndex,
    };
  }

  public static hasPlaceholders(text: string, placeholders: Placeholder[]): boolean {
    return placeholders.some(({ name }: Placeholder): boolean => text.search(name) !== -1);
  }

  public static replacePlaceholders(text: string, placeholders: Placeholder[]): string {
    let result: string = text;

    placeholders.forEach(({
      name,
      value,
    }: Placeholder): void => {
      result = result.replaceAll(name, value);
    });

    return result;
  }
}
