import { FormControlConfig } from '@/model/form-control-config';
import { SelectItem } from '@/model/select-item';
import { FormItemType } from '@/enums/form-item-type';
import { IForm } from '@/interface/i-form';
import { IFormControl } from '@/interface/i-form-control';
import { IFormControlConstructor } from '@/interface/i-form-control-constructor';
import { IFormControlFactoryConstructor } from '@/interface/i-form-control-factory-constructor';
import { FormControl } from '@/plugin/form/form-control';

export class FormControlFactory {
  protected form: IForm;

  protected selectItemsMap: Map<string, SelectItem[]>;

  protected model: Record<string, any>;

  protected parent?: IFormControl;

  protected static order: number = 0;

  constructor(options: IFormControlFactoryConstructor) {
    const {
      form,
      selectItemsMap,
      model,
      parent,
    }: IFormControlFactoryConstructor = options;

    this.form = form;
    this.selectItemsMap = selectItemsMap;
    this.model = model;
    this.parent = parent;
  }

  public create(controlConfig: FormControlConfig): IFormControl {
    FormControlFactory.order += 1;

    const constructor: IFormControlConstructor = {
      id: controlConfig.id,
      type: controlConfig.type,
      cssClass: controlConfig.cssClass,
      label: controlConfig.label,
      tooltip: controlConfig.tooltip,
      docs: controlConfig.docs,
      placeholder: controlConfig.placeholder,
      visible: controlConfig.visible,
      disabled: controlConfig.disabled,
      form: this.form,
      order: FormControlFactory.order,
      model: this.model,
    };

    if (controlConfig.type === FormItemType.SELECT) {
      if (typeof controlConfig.items === 'string') {
        constructor.items = this.selectItemsMap.get(controlConfig.items) || [];
      } else {
        constructor.items = [];
      }
    }

    if (controlConfig.type !== FormItemType.GROUP) {
      constructor.value = this.getDefaultValue(controlConfig);
    }

    if (typeof this.parent !== 'undefined') {
      constructor.parent = this.parent;
    }

    return new FormControl(constructor);
  }

  protected getDefaultValue(config: FormControlConfig): any {
    if (config.type === FormItemType.INPUT) {
      return config.value || '';
    } if (config.type === FormItemType.CHECKBOX) {
      return !!config.value;
    } if (config.type === FormItemType.SELECT) {
      if (typeof config.items === 'string') {
        const items: SelectItem[] | undefined = this.selectItemsMap.get(config.items) || [];

        if (typeof config.value === 'string') {
          const item: SelectItem | undefined = items.find((i: SelectItem): boolean => i.value === config.value);

          if (item) {
            return item;
          }
        }
      }
    }

    return '';
  }
}
