import StateBlock from 'markdown-it/lib/rules_block/state_block';
import { CompilerToken } from '@/enums/compiler-token';
import { Markup } from '@/plugin/template/markup';

export const blockConditionsRule = (
  state: StateBlock,
  startLine: number,
  endLine: number,
  silent: boolean,
): boolean => {
  if (!state.env.mdCompiler?.isEnabled) {
    return false;
  }

  let start: number = state.bMarks[startLine] + state.tShift[startLine];
  let max: number = state.eMarks[startLine];
  let text: string = state.src.substring(start, max);
  let currentLine: number;
  let token;
  let condition: string;

  if (Markup.isIf(text)) {
    condition = Markup.extractIfCondition(text);
  } else {
    return false;
  }

  if (silent) {
    return true;
  }

  currentLine = startLine;
  token = state.push(CompilerToken.IF, '', 1);
  token.info = condition;
  token.map = [startLine, currentLine];
  token.hidden = true;

  const { lineMax } = state;
  let lastConditionLine: number = currentLine;

  for (; ;) {
    currentLine += 1;
    if (currentLine >= endLine) {
      break;
    }

    start = state.bMarks[currentLine] + state.tShift[currentLine];
    max = state.eMarks[currentLine];
    text = state.src.substring(start, max);

    if (Markup.isElseIf(text)) {
      state.lineMax = currentLine;
      state.md.block.tokenize(state, lastConditionLine + 1, currentLine);

      condition = Markup.extractElseIfCondition(text);
      token = state.push(CompilerToken.ELSEIF, '', 1);
      token.block = true;
      token.info = condition;
      token.map = [currentLine, currentLine];
      token.hidden = true;

      lastConditionLine = currentLine;
      state.lineMax = lineMax;
    } else if (Markup.isElse(text)) {
      state.lineMax = currentLine;
      state.md.block.tokenize(state, lastConditionLine + 1, currentLine);

      token = state.push(CompilerToken.ELSE, '', 1);
      token.block = true;
      token.map = [currentLine, currentLine];
      token.hidden = true;

      lastConditionLine = currentLine;
      state.lineMax = lineMax;
    } else if (Markup.isEndIf(text)) {
      state.lineMax = currentLine;
      state.md.block.tokenize(state, lastConditionLine + 1, currentLine);

      token = state.push(CompilerToken.ENDIF, '', 1);
      token.block = true;
      token.map = [currentLine, currentLine];
      token.hidden = true;

      lastConditionLine = currentLine;
      state.lineMax = lineMax;

      break;
    }
  }

  state.line = currentLine + 1;
  state.lineMax = lineMax + 1;

  return true;
};
