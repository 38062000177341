import { set } from 'lodash-es';
import { SelectItem } from '@/model/select-item';
import { FormItemType } from '@/enums/form-item-type';
import { IForm } from '@/interface/i-form';
import { IFormCondition } from '@/interface/i-form-condition';
import { IFormControl } from '@/interface/i-form-control';
import { IFormControlConstructor } from '@/interface/i-form-control-constructor';
import { FormControlComponent } from '@/model/form-control-component';
import { Parser } from '@/plugin/template/parser';

export class FormControl implements IFormControl {
  public id: string;

  public type: FormItemType;

  public form: IForm;

  public order: number;

  public model: Record<string, any>;

  public cssClass?: string | IFormCondition;

  public label?: string;

  public tooltip?: string;

  public docs?: string;

  public placeholder?: string;

  public disabled?: string;

  public visible?: string;

  public items?: SelectItem[];

  public parent?: IFormControl;

  public children?: IFormControl[];

  public initialValue?: any;

  protected _value?: any;

  constructor(options: IFormControlConstructor) {
    const {
      id,
      type,
      form,
      order,
      model,
      cssClass,
      label,
      tooltip,
      docs,
      placeholder,
      disabled,
      visible,
      items,
      parent,
      value,
    }: IFormControlConstructor = options;

    this.id = id;
    this.type = type;
    this.form = form;
    this.order = order;
    this.model = model;
    this.cssClass = cssClass;

    if (typeof label === 'string') {
      this.label = label;
    }

    if (typeof tooltip === 'string') {
      this.tooltip = tooltip;
    }

    if (typeof docs === 'string') {
      this.docs = docs;
    }

    if (typeof placeholder === 'string') {
      this.placeholder = placeholder;
    }

    if (typeof disabled === 'string') {
      this.disabled = disabled;
    }

    if (typeof visible === 'string') {
      this.visible = visible;
    }

    if (this.isSelect) {
      this.items = items;
    }

    if (typeof parent !== 'undefined') {
      this.parent = parent;
    }

    if (!this.isGroup) {
      this._value = value;
      this.syncModel(value);
      this.initialValue = value;
    }

    form.addControl(this);
    parent?.addChildren(this);
  }

  get value(): any {
    return this._value;
  }

  set value(value: any) {
    if (!this.isGroup) {
      this._value = value;
      this.syncModel(value);
    }
  }

  get isInput(): boolean {
    return this.type === FormItemType.INPUT;
  }

  get isCheckbox(): boolean {
    return this.type === FormItemType.CHECKBOX;
  }

  get isSelect(): boolean {
    return this.type === FormItemType.SELECT;
  }

  get isGroup(): boolean {
    return this.type === FormItemType.GROUP;
  }

  get isRoot(): boolean {
    return typeof this.parent === 'undefined';
  }

  public addChildren(item: FormControl): FormControl {
    if (!Array.isArray(this.children)) {
      this.children = [];
    }

    this.children?.push(item);

    return this;
  }

  public getComponent(): FormControlComponent {
    let cssClass: string;

    if (typeof this.cssClass === 'string') {
      cssClass = this.cssClass;
    } else if (typeof this.cssClass === 'object') {
      cssClass = Parser.evaluatePredicate(this.cssClass.condition, this.model)
        ? this.cssClass.trueValue
        : this.cssClass.falseValue;
    } else {
      cssClass = 'tw-col-span-6';
    }

    const result: FormControlComponent = {
      id: this.id,
      formControl: this,
      type: this.type,
      visible: typeof this.visible === 'string' ? Parser.evaluatePredicate(this.visible, this.model) : true,
      cssClass,
      label: this.label,
      tooltip: this.tooltip,
      docs: this.docs,
      isInput: this.isInput,
      isCheckbox: this.isCheckbox,
      isSelect: this.isSelect,
      isGroup: this.isGroup,
    };

    if (this.isGroup) {
      result.components = this.children?.map((item: IFormControl) => item.getComponent()) || [];
      if (typeof this.visible !== 'string') {
        result.visible = !result.components.every((c: FormControlComponent) => !c.visible);
      }
    } else {
      result.props = {};
      result.value = this.value;

      if (typeof this.disabled === 'string') {
        result.props.disabled = Parser.evaluatePredicate(this.disabled, this.model);
      }

      if (this.isInput) {
        result.props.noDetails = true;

        if (typeof this.placeholder === 'string') {
          result.props.placeholder = this.placeholder;
        }

        result.component = 'input';
      } else if (this.isSelect) {
        result.props.noDetails = true;

        if (typeof this.placeholder === 'string') {
          result.props.placeholder = this.placeholder;
        }

        if (typeof this.items !== 'undefined') {
          result.props.items = this.items;
        }

        result.component = 'select';
      } else if (this.isCheckbox) {
        result.component = 'checkbox';
      }
    }

    return result;
  }

  protected syncModel(value: any): IFormControl {
    set(this.model, this.id, value);

    return this;
  }
}
